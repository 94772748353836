import React, { useState, useEffect, useRef } from 'react'

import SbEditable from 'storyblok-react'
import debounce from 'lodash/debounce'

import Hamburger from 'static/img/menu_bars.svg'
import XIcon from 'static/img/x_icon_dark.svg'

import * as Styled from './index.styled'
import Navigation from './navigation'

const Logo = React.lazy(async () => import('./logo'))

function Navbar({
  blok,
  lang,
  allLanguages,
  location,
  altLink,
  style,
  content,
  jobEntryBackground,
  is404,
}) {
  const isSSR = typeof window === 'undefined'
  const [menu, setMenu] = useState(null)
  const [isSticked, setIsSticked] = useState(false)
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [isVisible, setIsVisible] = useState(true)
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [isLangSelectorOpen, setIsLangSelectorOpen] = useState(false)
  const [whiteText, setWhiteText] = useState(false)
  const [hoverRef, isHovered] = useHover()
  const [chinese, setChinese] = useState(false)

  function closeHandler() {
    if (isNavOpen) {
      return toggleNavigation()
    }
  }

  function useHover() {
    const [value, setValue] = useState(false)
    const ref = useRef(null)
    const handleMouseOver = () => setValue(true)
    const handleMouseOut = () => setValue(false)
    useEffect(() => {
      const node = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)
        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    }, [ref.current])
    return [ref, value]
  }

  useEffect(() => {
    let hero
    const news = content === undefined ? '' : content.component
    const event = content === undefined ? '' : content.component

    if (event === 'event') {
      hero = ''
    } else {
      hero =
        content === undefined ||
        content.component === 'event_new' ||
        content.component === 'community_news' ||
        content.component === 'team_member_profile'
          ? ''
          : content?.body[0]
    }

    if (
      is404 ||
      jobEntryBackground === '' ||
      news === 'news' ||
      event === 'event' ||
      content?.component === 'event_new' ||
      content?.component === 'community_news' ||
      content?.component === 'team_member_profile' ||
      content?.component === 'aon_story' ||
      content?.component === 'opened' ||
      content?.body[0]?.component === 'add_to_calendar' ||
      content?.body[0]?.component === 'video_scroll' ||
      content?.body[0]?.component === 'text_hero' ||
      content?.body[0]?.component === 'bold_text_hero' ||
      (content?.body[0]?.component === 'banner' &&
        content?.body[0]?.background_color === 'white')
    ) {
      setWhiteText(false)
    } else if (hero.background_image != '' || hero.background_video != '') {
      setWhiteText(true)
    }

    document.body.style.setProperty('-webkit-overflow-scrolling', 'touch')

    lang === 'zh' ? setChinese(true) : setChinese(false)
  })

  function resolveHeaderMenuBySelectedCampus() {
    const defaultMenu = blok.menu.find(menu => menu.campus === 'Default')
    // const campusMenu = blok.menu.find(
    //   menu =>
    //     location.pathname.includes(menu.campus_slug) ||
    //     location.pathname.includes(`${menu.campus_slug}/`),
    // )

    setMenu(defaultMenu)
  }

  function toggleNavigation() {
    setIsVisible(true)
    setIsNavOpen(prevState => !prevState)
  }

  function toggleLangSelector() {
    setIsVisible(true)
    setIsLangSelectorOpen(prevState => !prevState)
  }

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset
    const isScrolledUp = prevScrollPos > currentScrollPos
    const shouldBeHidden = prevScrollPos - currentScrollPos > 0
    const isAlwaysVisible = currentScrollPos < 100

    setIsVisible((isScrolledUp && shouldBeHidden) || isAlwaysVisible)

    if (window.scrollY === 0) {
      setIsSticked(false)
    } else if (window.scrollY > 100) {
      setIsSticked(true)
    }

    setPrevScrollPos(currentScrollPos)
  }, 0)

  useEffect(() => {
    resolveHeaderMenuBySelectedCampus()
  }, [blok])

  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 833px)')

    if (!isMobile) {
      window.addEventListener('scroll', handleScroll, true)

      if (!isVisible) {
        setIsNavOpen(false)
        setIsLangSelectorOpen(false)
      }

      return () => window.removeEventListener('scroll', handleScroll, true)
    }
  }, [prevScrollPos, isVisible])

  return (
    <SbEditable content={blok}>
      <Styled.OptionsBar>
        <Styled.Hamburger icon={Hamburger} onClick={toggleNavigation} />
        <Styled.LangSelector
          options={allLanguages}
          currentLang={lang}
          location={location}
          isLangSelectorOpen={isLangSelectorOpen}
          toggleLangSelector={toggleLangSelector}
        />
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <Styled.Search lang={lang} noLabel />
          </React.Suspense>
        )}
      </Styled.OptionsBar>

      <Styled.Header
        ref={hoverRef}
        isLangSelectorOpen={isLangSelectorOpen}
        isNavOpen={isNavOpen}
        isHovered={isHovered}
        whiteText={whiteText}
        isSticked={isSticked}
        isVisible={isVisible}
        style={style}
      >
        <Styled.Container isSticked={isSticked}>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <Logo
                blok={blok}
                currentLang={lang}
                altLink={altLink}
                allLanguages={allLanguages}
                location={location}
                isNavOpen={isNavOpen}
                toggleNavigation={toggleNavigation}
                isLangSelectorOpen={isLangSelectorOpen}
                toggleLangSelector={toggleLangSelector}
                whiteText={whiteText}
                isSticked={isSticked}
                isHovered={isHovered}
              />
            </React.Suspense>
          )}
          <Navigation
            isHovered={isHovered}
            whiteText={whiteText}
            isSticked={isSticked}
            content={content}
            isNavOpen={isNavOpen}
            links={menu?.links}
            blok={blok}
            currentLang={lang}
            altLink={altLink}
            allLanguages={allLanguages}
            location={location}
            toggleNavigation={toggleNavigation}
            isLangSelectorOpen={isLangSelectorOpen}
            toggleLangSelector={toggleLangSelector}
            chinese={chinese}
          />
          {isNavOpen && (
            <Styled.XIcon
              icon={XIcon}
              onClick={closeHandler}
              isVisible={isNavOpen}
            />
          )}
        </Styled.Container>
      </Styled.Header>
    </SbEditable>
  )
}

export default Navbar
