import React from 'react'
import isEmpty from 'lodash/isEmpty'

import { Link } from 'components/Common'
import { useAccordion } from 'hooks'

import * as Styled from './navigationItem.styled'
import ArrowDownBlack from 'static/img/arrow_down_dark.svg'

function NavigationItem({
  link,
  whiteText,
  isSticked,
  isHovered,
  isNavOpen,
  toggleNavigation,
  chinese,
}) {
  const [ref, toggleAccordion, height, originalHeight] = useAccordion()

  return (
    <>
      <Styled.Item
        key={link.title}
        isOpen={height !== 0}
        whiteText={whiteText}
        isSticked={isSticked}
        isHovered={isHovered}
        chinese={chinese}
        className={link.title}
      >
        {isEmpty(link.items) ? (
          <Styled.Link
            chinese={chinese}
            isHovered={isHovered}
            whiteText={whiteText}
            isSticked={isSticked}
            blok={link}
          >
            {link.title}
          </Styled.Link>
        ) : (
          <Styled.Accordion
            chinese={chinese}
            isHovered={isHovered}
            isSticked={isSticked}
            whiteText={whiteText}
            onClick={toggleAccordion}
            isOpen={height !== 0}
          >
            <p chinese={chinese}>{link.title}</p>
            <img className="mobile-arrow" src={ArrowDownBlack} alt="Arrow" />
          </Styled.Accordion>
        )}

        {!isEmpty(link.items) && (
          <Styled.Dropdown
            chinese={chinese}
            isSticked={isSticked}
            className={link.title}
          >
            {link.items.map((item, index) => {
              return (
                <div key={index}>
                  {item.items.map((item, index) => {
                    return (
                      <Styled.DropdownItem
                        key={`${item.title}-${index}`}
                        chinese={chinese}
                      >
                        <Link
                          className="dropdown-item"
                          chinese={chinese}
                          blok={item}
                        >
                          {item.title}
                        </Link>
                      </Styled.DropdownItem>
                    )
                  })}
                </div>
              )
            })}
          </Styled.Dropdown>
        )}
      </Styled.Item>

      <Styled.List
        ref={ref}
        isNavOpen={isNavOpen}
        height={height}
        originalHeight={originalHeight}
        toggleNavigation={toggleNavigation}
      >
        {link.items.map((item, index) => {
          return (
            <div key={index}>
              {item.items.map((item, index) => {
                return (
                  <Styled.DropdownItem key={`${item.title}-${index}`}>
                    <NavigationItem link={item} />
                  </Styled.DropdownItem>
                )
              })}
            </div>
          )
        })}
      </Styled.List>
    </>
  )
}

export default NavigationItem
