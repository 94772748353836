import React from 'react'
import useJobsFiltersReducer from 'components/NewOpenRoles/hooks/useJobsFiltersReducer'
import { Col, Container, Row } from 'components/Grid'
import CampusesSelectField from './CampusesSelectField'
import DepartmentsSelectField from './DepartmentsSelectField'
import SearchField from './SearchField'
import FilteringStatusText from './FilteringStatusText'
import { JobsFiltersWrapper } from './styles'

export default function JobsFilters({ onChange, searchConfig, pageLocation }) {
  const [data, actions] = useJobsFiltersReducer(onChange, searchConfig)

  const {
    jobs,
    filteredJobs,
    campuses,
    campusesSelectedCount,
    departments,
    departmentsSelectedCount,
    searchInputValue,
    resetKey,
  } = data

  const city =
  ['/careers/open-roles-avenues-sao-paulo', '/pt/careers/open-roles-avenues-sao-paulo', '/zh/careers/open-roles-avenues-sao-paulo'].includes(pageLocation.pathname)
    ? 'São Paulo'
    : ['/careers/open-roles-avenues-new-york', '/pt/careers/open-roles-avenues-new-york', '/zh/careers/open-roles-avenues-new-york'].includes(pageLocation.pathname)
    ? 'New York'
    : null;

  // Filter jobs based on the city
  const jobsInCity = filteredJobs.filter(job => job.location?.city === city)

  const {
    toggleCampus,
    toggleDepartment,
    updateSearchInputValue,
    resetFilters,
  } = actions

  function renderJobsFilters() {
    return (
      <Container>
        <Row>
          <Col width={[1, 1, 1 / 2]} pb={[45, 45, 0]}>
            <DepartmentsSelectField
              resetKey={resetKey}
              nodes={departments}
              onToggle={toggleDepartment}
            />
          </Col>
          <Col width={[1, 1, 1 / 2]} alignSelf="flex-end">
            <SearchField
              value={searchInputValue}
              onChange={updateSearchInputValue}
            />
          </Col>
        </Row>
      </Container>
    )
  }

  if (!departments.length || !campuses.length) {
    return null
  }

  return (
    <JobsFiltersWrapper>
      <Container>
        <Row flexDirection="column">
          <Col>{renderJobsFilters()}</Col>
          <Col>
            <FilteringStatusText
              jobsCount={jobs.length}
              filteredJobsCount={jobsInCity.length}
              campusesCount={campuses.length}
              campusesSelectedCount={campusesSelectedCount}
              departmentsCount={departments.length}
              departmentsSelectedCount={departmentsSelectedCount}
              resetFilters={resetFilters}
            />
          </Col>
        </Row>
      </Container>
    </JobsFiltersWrapper>
  )
}
