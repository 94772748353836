import React from 'react'
import { Container, Row, Col } from 'components/Grid'
import JobsListItem from './JobsListItem'

export default function JobsList({ filteredJobs, lang, pageLocation }) {
  // Determine the city based on the pageLocation.pathname

  function renderJobsListItems() {
    const city = [
      '/careers/open-roles-avenues-sao-paulo',
      '/pt/careers/open-roles-avenues-sao-paulo',
      '/zh/careers/open-roles-avenues-sao-paulo',
    ].includes(pageLocation.pathname)
      ? 'São Paulo'
      : [
          '/careers/open-roles-avenues-new-york',
          '/pt/careers/open-roles-avenues-new-york',
          '/zh/careers/open-roles-avenues-new-york',
        ].includes(pageLocation.pathname)
      ? 'New York'
      : null

    // Filter jobs based on the city
    const jobsInCity = filteredJobs.filter(job => job.location?.city === city)
    // Map through the filtered jobs
    return jobsInCity.map(job => {
      const { id, jobId, jobAdId, name, customField } = job

      const department = customField
        .filter(
          field =>
            field.fieldLabel === 'Department' ||
            field.fieldLabel === 'Division',
        )
        .map(field => field.valueLabel)

      const location = customField
        .filter(field => field.fieldLabel === 'Work Location')
        .map(field => field.valueLabel)

      const campus = customField
        .filter(field => field.fieldLabel === 'Campus')
        .map(field => field.valueLabel)

      const noDeptDiv = department.every((val, arr) => val === arr[0])
      const departmentField = noDeptDiv ? campus : department

      return (
        <JobsListItem
          key={id}
          sm_key={jobAdId}
          name={name}
          departmentField={departmentField}
          campus={location}
          lang={lang}
        />
      )
    })
  }

  if (!filteredJobs || !filteredJobs.length) {
    return null
  }

  return (
    <Container>
      <Row>
        <Col>{renderJobsListItems()}</Col>
      </Row>
    </Container>
  )
}
