import styled, { css, keyframes } from 'styled-components'

import { Link as LinkComponent } from 'components/Common'

const LinkStyles = css`
  display: flex;
  margin: 0;
  font-size: ${({ chinese }) => chinese ? '16px !important' : '13px !important'};
  font-weight: ${({ chinese }) => chinese ? '400 !important' : '300'};
  font-family: Halyard-SemiBold;
  letter-spacing: 0.8px !important;
  font-feature-settings: 'ss18';
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @media (min-width: 834px) {
    color: ${({ whiteText, isSticked, isHovered, theme }) =>
      isSticked || isHovered
        ? theme.colors.black
        : whiteText
        ? theme.colors.white
        : theme.colors.black};

    :hover {
      color: ${({ whiteText, isSticked, isHovered, theme }) =>
        isSticked || isHovered
          ? theme.colors.black
          : whiteText
          ? theme.colors.white
          : theme.colors.black};
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }
`

const showHiddenContent = height => keyframes`
  from {
    height: 0;
  }
  to {
    height: ${height}px;
  }
`

const hideHiddenContent = originalHeight => keyframes`
  from {
    height: ${originalHeight}px;
  }
  to {
    height: 0;
  }
`

export const Accordion = styled.p`
  ${LinkStyles};

  img {
    width: 10px;
    margin: 0 0 0 8px;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      img {
        transform: rotate(180deg);
      }
    `}
`

export const Dropdown = styled.ul`
  display: none;

  @media (min-width: 834px) {
    opacity: 0;
    box-shadow: -3px 24px 20px -24px rgba(0, 0, 0, 0.3);
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 99vw;
    top: 37px;
    left: 50%;
    margin: 0;
    padding: 24px 0 38px 0;
    background: ${({ theme }) => theme.colors.white};
    pointer-events: none;
    transform: translateX(-50%);
    transition: opacity 0s ease 0s, box-shadow 0s ease 0s, transform 0s ease;
  }
`

export const DropdownItem = styled.div`
  padding: 0 0 4px 0;
  width: ${({ chinese }) => (chinese ? '150px' : '175px')};

  a {
    text-transform: uppercase;
    margin: 0;
    font-size: ${({ chinese }) =>
      chinese ? '16px !important' : '13px !important'};
    letter-spacing: 0.6px;
    font-weight: 300;
    line-height: 16px;
    display: inline-flex;
  }
  a:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`

export const Item = styled.li`
  padding-bottom: 14px;
  overflow: hidden;

  ${Accordion} {
    p {
      font-size: ${({ chinese }) =>
        chinese ? '16px !important' : '13px !important'};
      letter-spacing: 0.8px;
    }

    p: hover {
      text-decoration: underline !important;
      text-underline-offset: 5px;
    }
  }

  @media (min-width: 834px) {
    display: flex;
    align-items: center;
    padding: 0 14px 0 14px;
    overflow: initial;
    height: 100%;

    :hover ${Dropdown} {
      opacity: 1;
      pointer-events: all;
    }

    .mobile-arrow {
      display: none;
    }

    :hover ${Accordion} {
      p {
        text-decoration: underline;
        text-underline-offset: 5px;
      }
    }
  }
`

export const Link = styled(LinkComponent)`
  ${LinkStyles};
`

export const List = styled.ul`
  margin: 0 0 0 20px;
  transform-origin: top;
  overflow: hidden;
  position: relative;
  height: 0;
  background: rgb(213 213 213 / 10%) ;
  margin-left: 10px;

  & a {
    display: inline-block;
  }

  ${({ height, originalHeight }) =>
    height === 0
      ? css`
          animation: ${hideHiddenContent(originalHeight)} 0.5s ease-in-out;
        `
      : css`
          animation: ${showHiddenContent(height)} 0.5s ease-in-out;
          height: auto;
          padding: 10px ;
          overflow: auto ;
        `}

  @media (min-width: 834px) {
    display: none;
  }
`
