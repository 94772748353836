import React, { useState } from 'react'
import { RemoveScroll } from 'react-remove-scroll'

import * as Styled from './navigation.styled'
import NavigationItem from './navigationItem'
import LogoBug from '/static/assets/ave-logo-sign.png'

const SearchLazy = React.lazy(async () => import('./Search'))
const LangSelector = React.lazy(async () => import('./langSelector'))

function Navigation({
  blok,
  currentLang,
  allLanguages,
  location,
  chinese,
  toggleNavigation,
  isLangSelectorOpen,
  toggleLangSelector,
  links,
  isNavOpen,
  whiteText,
  isSticked,
  isHovered,
}) {
  const isSSR = typeof window === 'undefined'

  const disableScroll = () => {
    return (
      <RemoveScroll>
        <Styled.Navigation
          chinese={chinese}
          isHovered={isHovered}
          whiteText={whiteText}
          isSticked={isSticked}
          isOpen={isNavOpen}
        >
          <div />
          {links && (
            <Styled.List>
              {links.map((link, index) => (
                <NavigationItem
                  key={link.title}
                  link={link}
                  isNavOpen={isNavOpen}
                  whiteText={whiteText}
                  isSticked={isSticked}
                  isHovered={isHovered}
                  toggleNavigation={toggleNavigation}
                  chinese={chinese}
                />
              ))}
            </Styled.List>
          )}
        </Styled.Navigation>
      </RemoveScroll>
    )
  }

  const enableScroll = () => {
    return (
      <Styled.Navigation
        chinese={chinese}
        isHovered={isHovered}
        whiteText={whiteText}
        isSticked={isSticked}
        isOpen={isNavOpen}
      >
        <div />
        {links && (
          <Styled.List>
            {links.map((link, index) => (
              <NavigationItem
                key={link.title}
                link={link}
                isNavOpen={isNavOpen}
                whiteText={whiteText}
                isSticked={isSticked}
                isHovered={isHovered}
                toggleNavigation={toggleNavigation}
                chinese={chinese}
              />
            ))}
          </Styled.List>
        )}
        <Styled.ToolbarTwo>
          <Styled.RightColumn>
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <SearchLazy
                  whiteText={whiteText}
                  isHovered={isHovered}
                  isSticked={isSticked}
                  lang={currentLang}
                  noLabel
                />
              </React.Suspense>
            )}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <LangSelector
                  options={allLanguages}
                  currentLang={currentLang}
                  location={location}
                  isLangSelectorOpen={isLangSelectorOpen}
                  toggleLangSelector={toggleLangSelector}
                  isSticked={isSticked}
                  isHovered={isHovered}
                  whiteText={whiteText}
                />
              </React.Suspense>
            )}
          </Styled.RightColumn>
        </Styled.ToolbarTwo>
      </Styled.Navigation>
    )
  }

  return <>{isNavOpen ? disableScroll() : enableScroll()}</>
}

export default Navigation
