import React from 'react'
import styled from 'styled-components'

import { IconButton } from 'components/Common'

import LangSelectorComponent from './langSelector'
const SearchLazy = React.lazy(async () => import('./Search'))

export const Header = styled.header`
  position: ${({ isNavOpen, isLangSelectorOpen }) =>
    isNavOpen || isLangSelectorOpen ? 'fixed' : 'absolute'};
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 9999;

  @media (min-width: 834px) {
    position: ${({ isSticked }) => (isSticked ? 'fixed' : 'absolute')};
    box-shadow: ${({ isSticked }) =>
      isSticked ? '-3px 3px 24px rgba(0, 0, 0, .3)' : 'none'};
    transform: ${({ isVisible }) =>
      isVisible ? 'translateY(0)' : 'translateY(-100%)'};
      transition: background-color 0s ease, box-shadow 0s ease,
      transform 0s ease;
    background-color: ${({ isSticked, isHovered, theme }) =>
      isSticked || isHovered ? theme.colors.white : 'transparent'};
    background-image: ${({ isSticked, isHovered, whiteText }) =>
      isSticked || isHovered || !whiteText
        ? 'none'
        : 'linear-gradient(rgba(0,0,0,.34), rgba(255,255,255,0));'}
    transition: background-color 0s ease 0s, box-shadow 0s ease 0s,
      transform 0s ease;
  }
`

export const Container = styled.div`
  margin: 0 auto;
  padding: 10px 16px;

  @media (min-width: 834px) {
    padding: ${({ isSticked }) => (isSticked ? '8px 16px 0 16px' : '32px 16px')};
  }
`

export const OptionsBar = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 0;
  z-index: 9999;

  @media (min-width: 834px) {
    display: none;
  }
`

export const Hamburger = styled(IconButton)`
  margin-right: 80px;

  img {
    margin: 0;
  }
`

export const Search = styled(SearchLazy)``

export const LangSelector = styled(LangSelectorComponent)`
  display: block;
  margin-right: 80px;
`

export const XIcon = styled(IconButton)`
  position: fixed;
  bottom: 20px;
  left: 48%;
  background: white;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px 0px rgb(51 51 51 / 15%);

  img {
    width: 16px;
    height: 16px;
    margin: 0;
  }

  @media (min-width: 834px) {
    display: none;
  }
`
